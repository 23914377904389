import {
  Directive,
  ElementRef,
  inject,
  input,
  numberAttribute,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { debounceTime, filter, fromEvent, tap } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mlk-element-pagination',
  host: {
    '[style.max-height]': 'height()',
    '[style.display]': '"block"',
    '[style.overflow-y]': '"auto"',
  },
  standalone: true,
})
export class ElementPaginationDirective implements OnInit {
  private element = inject<ElementRef<HTMLElement>>(ElementRef);

  readonly canNext = input.required<boolean>();

  readonly maxNumberOfPage = input(1, { transform: numberAttribute });

  readonly height = input('19rem');

  readonly nextPage = output<number>();

  private readonly _currentPage = signal(1);

  public ngOnInit(): void {
    fromEvent(this.element.nativeElement, 'scroll', { passive: true })
      .pipe(
        debounceTime(500),
        filter(() => this.canNext() && this.isBottom()),
        tap(() => {
          if (this._currentPage() < this.maxNumberOfPage()) {
            const nextPage = this._currentPage() + 1;
            this._currentPage.set(nextPage);
            this.nextPage.emit(nextPage);
          }
        })
      )
      .subscribe();
  }

  public isBottom(): boolean {
    const { scrollHeight, scrollTop, offsetHeight } =
      this.element.nativeElement;

    if (scrollTop === scrollHeight - offsetHeight) {
      return true;
    }
    return false;
  }
}
